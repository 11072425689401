button.regular-btn {
  display: inline-block;
  color: white;
  border-width: 1px;
  border-radius: 3px;
  @apply my-auto h-fit p-2 text-sm;
}

button.shorter-btn {
  display: inline-block;
  padding-top: 6px;
  padding-bottom: 6px;
  color: white;
  border-width: 1px;
  border-radius: 3px;
  @apply my-auto h-fit px-2 text-sm;
}

button.shorter-btn:disabled,
button.regular-btn:disabled {
  cursor: not-allowed;
  filter: opacity(0.6);
}

button.locked-btn {
  opacity: 0.8;
  @apply hover:cursor-not-allowed;
}

/* DELETE, DROP */
button.delete-btn {
  background: var(--DELETE-BTN);
  border-color: var(--DELETE-BTN);
}

button.delete-btn:hover {
  background: var(--DELETE-BTN-HOVER);
}

/* PRINT, EXPORT */
button.print-btn {
  background: var(--PRINT-BTN);
  border-color: var(--PRINT-BTN);
}

button.print-btn:hover {
  background: var(--PRINT-BTN-HOVER);
}

/* GET, SEARCH */
button.search-btn {
  background: var(--SEARCH-BTN);
  border-color: var(--SEARCH-BTN);
}

button.search-btn:hover {
  background: var(--SEARCH-BTN-HOVER);
}

/* ADD, DOWNLOAD, UPLOAD, NEW */
button.add-btn {
  background: var(--ADD-BTN);
  border-color: var(--ADD-BTN);
}

button.add-btn:hover {
  background: var(--ADD-BTN-HOVER);
}

/* CLEAR, CANCEL */
button.clear-btn {
  background: var(--CLEAR-BTN);
  border-color: var(--CLEAR-BTN);
}

button.clear-btn:hover {
  background: var(--CLEAR-BTN-HOVER);
}

/* SAVE, UPDATE, GENERATE */
button.save-btn {
  background: var(--SAVE-BTN);
  border-color: var(--SAVE-BTN);
}

button.save-btn:hover {
  background: var(--SAVE-BTN-HOVER);
}

/* SHOW, PREVIEW */
button.show-btn {
  background: var(--SHOW-BTN);
  border-color: var(--SHOW-BTN);
}

button.show-btn:hover {
  background: var(--SHOW-BTN-HOVER);
}

/* OTHERS */
button.misc-btn {
  background: var(--MISC-BTN);
  border-color: var(--MISC-BTN);
}

button.misc-btn:hover {
  background: var(--MISC-BTN-HOVER);
}
