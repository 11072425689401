:root {
  /* COLORS */

  --PRIMARY: #264d8b;
  --PRIMARY-DARKER: #002c5f;
  --PRIMARY-LIGHTER: #3c8dbc;

  /* BUTTONS */

  --DELETE-BTN: #dc3545;
  --DELETE-BTN-HOVER: #c82333;

  --PRINT-BTN: #dd4b39;
  --PRINT-BTN-HOVER: #d73925;

  --SEARCH-BTN: #007bff;
  --SEARCH-BTN-HOVER: #0069d9;

  --ADD-BTN: #3c8dbc;
  --ADD-BTN-HOVER: #367fa9;

  --CLEAR-BTN: #666666;
  --CLEAR-BTN-HOVER: #4e4e4e;

  --SAVE-BTN: #00a65a;
  --SAVE-BTN-HOVER: #008d4c;

  --SHOW-BTN: #f39c12;
  --SHOW-BTN-HOVER: #e08e0b;

  --MISC-BTN: #264d8b;
  --MISC-BTN-HOVER: #1d365f;

  /* TEXT COLORS */

  --TEXT-MAIN: #333;
  --TEXT-SECONDARY: #555;
  --TEXT-HOVER: #999;
  --TEXT-RED-WARNING: #dd4b39;

  /* BACKGROUND COLORS */

  --MAIN-BG: #f1f1f1;
  --SECONDARY-BG: #fff;
}

:root[data-theme="HYUNDAI"] {
  /* COLORS */
}

:root[data-theme="JEEP"] {
  /* COLORS */
  --PRIMARY: #404c01;
  --PRIMARY-DARKER: #806942;
  --PRIMARY-LIGHTER: #9b7f4e;

  /* BUTTONS */

  --DELETE-BTN: #dc3545;
  --DELETE-BTN-HOVER: #c82333;

  --PRINT-BTN: #dd4b39;
  --PRINT-BTN-HOVER: #d73925;

  --SEARCH-BTN: #9b7f4e;
  --SEARCH-BTN-HOVER: #806942;

  --ADD-BTN: #9b7f4e;
  --ADD-BTN-HOVER: #806942;

  --CLEAR-BTN: #8d907b;
  --CLEAR-BTN-HOVER: #6d6f60;

  --SAVE-BTN: #00a65a;
  --SAVE-BTN-HOVER: #008d4c;

  --SHOW-BTN: #f39c12;
  --SHOW-BTN-HOVER: #e08e0b;

  --MISC-BTN: #404c01;
  --MISC-BTN-HOVER: #607007;
}
