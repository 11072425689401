input[type="checkbox"] {
  border-radius: 0.25rem;
  cursor: pointer;
  height: 1rem;
  width: 1rem;
  color: var(--PRIMARY);
  @apply border-gray-300 bg-gray-100;
}

input[type="checkbox"].checkbox-single {
  border-radius: 0.125rem;
  height: 1.5rem;
  width: 1.5rem;
  @apply text-xl;
}

input[type="checkbox"].checkbox-table {
  display: block;
  @apply mx-auto;
}

input[type="checkbox"].checkbox-table:disabled {
  color: rgb(185, 185, 185);
}

input[type="checkbox"]:focus {
  box-shadow: none;
  color: var(--PRIMARY-LIGHTER);
  outline: 2px solid var(--PRIMARY-LIGHTER);
}

input[type="checkbox"].checkbox-table:focus,
input[type="checkbox"].checkbox-form:focus {
  outline-offset: 0.1rem;
}

input[type="checkbox"].checkbox-single:focus {
  outline-offset: 0.2rem;
}

input[type="radio"] {
  box-shadow: none;
  color: var(--PRIMARY);
  cursor: pointer;
  outline: none;
  @apply mx-2;
}

input[type="radio"]:focus {
  box-shadow: none;
  color: var(--PRIMARY-LIGHTER);
  outline: 2px solid var(--PRIMARY-LIGHTER);
}

input[type="checkbox"]:disabled,
input[type="radio"]:disabled {
  cursor: default;
  filter: brightness(0.95);
}

.textarea {
  background-color: white;
  border: 1px solid #ddd;
  @apply text-sm;
}

.textarea:hover {
  border-color: var(--PRIMARY-LIGHTER);
}

.textarea:focus {
  border-color: var(--PRIMARY);
  outline: none;
  box-shadow: 4px 4px 4px 0 rgba(98, 170, 252, 0.1);
}

.textarea:disabled {
  border: 1px solid #ddd;
  filter: brightness(0.95);
}

label.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
}

/* Hide default HTML checkbox */
label.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

/* The slider */
span.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

span.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

label.switch input:checked + .slider {
  background-color: var(--PRIMARY);
}

label.switch input:focus + .slider {
  box-shadow: 0 0 1px var(--PRIMARY);
}

label.switch input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

/* Rounded sliders */
span.slider.round {
  border-radius: 34px;
}

span.slider.round:before {
  border-radius: 50%;
}

