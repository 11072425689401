@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";

@import "~slick-carousel/slick/slick.css";
@import "~slick-carousel/slick/slick-theme.css";
@import "~animate.css/animate.min";
@import "~@fortawesome/fontawesome-free/css/all.css";

@import "node_modules/angular-notifier/styles.css";

/* COMPONENTS CLASSES IMPORTS */

@import "../src/assets/classes/buttons.css";
@import "../src/assets/classes/inputs.css";
@import "../src/assets/classes/colors.css";

/* FONTS IMPORTS */

@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@200;300;400;600;700;800&display=swap");

@font-face {
  font-family: "HSansHead";
  src: url("/assets/fonts/HyundaiSansHead-Light.woff2");
  font-weight: 300; /* light */
}

@font-face {
  font-family: "HSansHead";
  src: url("/assets/fonts/HyundaiSansHead-Regular.woff2");
  font-weight: 400; /* normal */
}

@font-face {
  font-family: "HSansHead";
  src: url("/assets/fonts/HyundaiSansHead-Medium.woff2");
  font-weight: 500; /* medium */
}

@font-face {
  font-family: "HSansHead";
  src: url("/assets/fonts/HyundaiSansHead-Bold.woff2");
  font-weight: 700; /* bold */
}

@font-face {
  font-family: "HSansText";
  src: url("/assets/fonts/HyundaiSansText-Regular.woff2");
  font-weight: 400; /* normal */
}

@font-face {
  font-family: "HSansText";
  src: url("/assets/fonts/HyundaiSansText-Medium.woff2");
  font-weight: 500; /* medium */
}

@font-face {
  font-family: "HSansText";
  src: url("/assets/fonts/HyundaiSansText-Bold.woff2");
  font-weight: 700; /* bold */
}

html {
  color: var(--TEXT-MAIN);
  font-family: HSansText;
  width: 100%;
}

/* COMMON COMPONENTS */

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: HSansHead;
}

.page-container {
  background-color: var(--MAIN-BG);
  min-height: calc(100vh - 48px);
  @apply p-4;
}

.content-container {
  background-color: var(--SECONDARY-BG);
  border-radius: 0.25rem;
  max-width: 100%;
  @apply mt-2.5 p-4 shadow;
}

.content-container__tabs {
  background-color: var(--SECONDARY-BG);
  border-radius: 0 0.25rem 0.25rem;
  max-width: 100%;
  @apply p-4 shadow;
}

.form-container {
  display: grid;
  @apply grid-cols-2 gap-x-16 gap-y-2 px-8 py-4 text-sm;
}

h1.main-heading {
  font-weight: 400;
  @apply mb-4 text-2xl;
}

h2.sub-heading {
  font-weight: 400;
  @apply pb-4 text-xl;
}

h3.subsub-heading {
  font-weight: 400;
  @apply text-base;
}

/* TABS FEATURE CLASSES */

.tabs-container {
  @apply mt-4 flex text-sm;
}

.tab-item {
  border-top: 0.25rem;
  cursor: pointer;
  @apply p-3;
}

.tab-item:hover {
  background-color: #f7f7f7;
  border-radius: 0.25rem 0.25rem 0 0;
  font-weight: bold;
}

.tab-item_selected {
  background-color: var(--SECONDARY-BG);
  border-radius: 0.25rem 0.25rem 0 0;
  clip-path: inset(-3px -3px 0 -3px);
  cursor: default;
  font-weight: bold;
  @apply p-3 shadow;
}

/* DARK THEME */

/* @media (prefers-color-scheme: dark) {
  .alt-darker-blue-icon {
    stroke: white;
  }
} */

.ngx-loading-logo {
  animation: pulse 2s infinite;
  width: 240px !important;
  height: auto !important;
}

@keyframes pulse {
  0% {
    opacity: 0.2;
  }

  100% {
    opacity: 1;
  }
}

.form-column {
  display: flex;
  flex-direction: column;
}

.form-group {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
  margin-right: 30px;
}

.form-group input {
  width: 70%;
  padding: 8px;
  border: 1px solid #ccc;
}

.form-group select {
  width: 70%;
  padding: 8px;
  box-sizing: border-box;
  border: 1px solid #ccc;
}

.form-group select:focus {
  border-color: #3c8dbc;
  box-shadow: none;
}

.form-group input:focus {
  outline: none;
  border-color: #3c8dbc;
  box-shadow: none;
}

.form-group label {
  font-family: "HSansText", sans-serif;
  font-weight: 400;
}
